import React, { useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { ProductModel, SingleProductIncomeExpenseHistoryViewModel } from "features/app/utils/models/notificationModel";
import DefectView from "features/warehouse/components/defect/defect-view/DefectView";
import LaboratoryDrawer from "features/warehouse/components/labaratory/laboratory-drawer/LaboratoryDrawer";
import TransferView from "features/warehouse/components/transfers/transfer-view/TransferView";
import {
  getQuantityStyle,
  getSourceContent
} from "features/warehouse/components/warehouse/income-expense-history-drawer/bottom/bottom";
import IncomeExpenseHistoryDrawer from "features/warehouse/components/warehouse/income-expense-history-drawer/income-expense-history-drawer";
import { useGetWarehouseProductHistory } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import { cx, RU, TooltipShortName } from "modules/common";

import { PartyView } from "pages/index";

import { LoadingIndicator } from "components";

import IncomeExpenseHistoryFilterDrawer from "../filter-drawer";

import Actions from "./actions";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";
import TitleActions from "features/warehouse/components/income-expense-history/table/title-actions";


const IncomeExpenseHistoryTable: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(1);
  const { data, isFetching } = useGetWarehouseProductHistory();

  const columns: ColumnsType<
    SingleProductIncomeExpenseHistoryViewModel & {
      product: ProductModel;
      unit: UnitModel;
    }
  > = [
    {
      title: `${t("warehouse.Resurs nomi va turi")}`,
      render: (
        record: SingleProductIncomeExpenseHistoryViewModel & {
          product: ProductModel;
          unit: UnitModel;
        }
      ) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <PopoverShortName title={record?.product?.name[i18n.language]} length={150} />
        </div>
      )
    },
    {
      title: t("warehouse.Miqdori"),
      render: (_value: any, record) => (
        <div className={getQuantityStyle(record)?.className}>
          {getQuantityStyle(record)?.symbol} {record?.total_initial_quantity?.toLocaleString(RU)}{" "}
          {record?.unit?.symbol[i18n.language]}
        </div>
      ),
      width: 250
    },
    {
      title: t("warehouse.Sana"),
      dataIndex: "created_date"
    },
    {
      title: t("warehouse.Manba"),
      render: (_value: any, record) => <div>{getSourceContent(record)}</div>,
      width: 120
    },
    {
      title: t("warehouse.Omborxona"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.warehouse?.name} length={15} />
        </div>
      ),
      width: 250
    },
    {
      title: t("warehouse.Loyiha"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.project?.name} length={15} />
        </div>
      ),
      width: 250
    },
    {
      title: "",
      render: (_value: any, record) => <Actions record={record} />,
      width: 70
    }
  ];

  const itemRender = (
    _: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    element: React.ReactNode
  ) => {
    if (type === "prev") {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>
          <ArrowNarrowIcon /> {t("Xodimlar.Oldingi")}
        </a>
      );
    }
    if (type === "next") {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>
          {t("Xodimlar.Keyingi")}
          <ArrowNarrowIcon rotate={180} />
        </a>
      );
    }
    return element;
  };

  const table_title = () => (
    <div className="flex items-center justify-end gap-3">
      <PaymentSearch/>
      <IncomeExpenseHistoryFilterDrawer />
      <TitleActions/>
    </div>
  );

  return (
    <div>
      <Table<
        SingleProductIncomeExpenseHistoryViewModel & {
          product: ProductModel;
          unit: UnitModel;
        }
      >
        columns={columns}
        dataSource={data}
        className={cx("footer_table")}
        title={table_title}
        pagination={{
          onChange: page => setPage(page),
          current: page,
          pageSize: 10,
          total: data?.length,
          showSizeChanger: false,
          position: ["bottomLeft"],
          itemRender
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isFetching,
          indicator: LoadingIndicator
        }}
      />
      <PartyView />
      <LaboratoryDrawer />
      <TransferView />
      <DefectView />
      <IncomeExpenseHistoryDrawer />
    </div>
  );
};

export default IncomeExpenseHistoryTable;
