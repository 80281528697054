import React from "react";
import GeneralEditing from "features/app/components/general-editing/GeneralEditing";
import { generalEditKeys } from "features/app/utils/constants/generalEditData";

import { useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import { Top } from "./top";

const Works: React.FC = () => {
  const { generalEdit } = useAppSelector(state => state.appReducer);
  const { setSelectOrderProducts } = supplyActions

  const generalUpdateMutations = {
    [generalEditKeys.DONE_WORKS]: {
      isDelete: true,
      dispatchFunc: setSelectOrderProducts
    }
  }

  return (
    <div>
      <Top />
      <GeneralEditing {...generalUpdateMutations[generalEdit.key as keyof typeof generalUpdateMutations]} />
    </div>
  );
}

export default Works;
