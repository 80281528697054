import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { ProductModel, SingleProductIncomeExpenseHistoryViewModel } from "features/app/utils/models/notificationModel";
import EyeIcon from "features/projects/assets/icons/EyeIcon";
import WorkModalTabs from "features/projects/components/detailed-project/work/modal/tabs/WorkModalTabs";
import { useTranslation } from "react-i18next";

import { defectViewActions } from "store/reducers/defectViewReducer";
import { laboratoryDrawerActions } from "store/reducers/laboratoryDrawerReducer";
import { partyReducerActions } from "store/reducers/partyReducer";
import { transferActions } from "store/reducers/transferReducer";
import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import { ClockFastForwardIcon, DotsVerticalIcon } from "components";

type Props = {
  record: SingleProductIncomeExpenseHistoryViewModel & {
    product: ProductModel;
    unit: UnitModel;
  };
};

const Actions: React.FC<Props> = ({ record }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [taskProgressId, setTaskProgressId] = React.useState<number | null>(null);

  const { setPartyView } = partyReducerActions;
  const { setOpenDrawer } = laboratoryDrawerActions;
  const { setVisibleView } = transferActions;
  const { setDefectView } = defectViewActions;
  const { setResourceHistory } = warehouseProductsAction;

  const onShowDetail = () => {
    if (record?.order_id) {
      dispatch(
        setPartyView({
          visible: true,
          party_id: record?.order_id,
          status: "rejected",
          type: "supply"
        })
      );
    }
    if (record?.laboratory_id) {
      dispatch(
        setOpenDrawer({
          visible: true,
          id: record?.laboratory_id,
          isViewing: true
        })
      );
    }
    if (record?.warehouse_transfer_id) {
      dispatch(
        setVisibleView({
          visible: true,
          data: {
            ...record,
            id: record?.warehouse_transfer_id
          } as never
        })
      );
    }
    if (record?.task_id && record?.task_progress_id) {
      setOpen(true);
      setTaskProgressId(record?.task_progress_id);
    }
    if (record?.warehouse_defect_id) {
      dispatch(
        setDefectView({
          data: {
            ...record,
            id: record?.warehouse_defect_id
          } as never,
          visible: true
        })
      );
    }
  };

  const onOpenIncomeExpenseHistoryDrawer = () => {
    dispatch(
      setResourceHistory({
        visible: true,
        product: record as never
      })
    );
  };

  const dropdownRender = (child: React.ReactNode) => <div onClick={e => e.stopPropagation()}>{child}</div>;

  const items = [
    {
      key: "0",
      label: (
        <div className="flex gap-3 text-sm font-medium text-gray-700" onClick={onShowDetail}>
          <EyeIcon /> {t("warehouse.Ko'rish")}
        </div>
      )
    },
    {
      key: "1",
      label: (
        <div className="flex gap-3 text-sm font-medium text-gray-700" onClick={onOpenIncomeExpenseHistoryDrawer}>
          <ClockFastForwardIcon /> {t("warehouse.Kirim-chiqim tarixi")}
        </div>
      )
    }
  ];

  return (
    <div>
      <Dropdown menu={{ items }} trigger={["click"]} dropdownRender={dropdownRender}>
        <div className="cursor-pointer" onClick={e => e.stopPropagation()}>
          <DotsVerticalIcon />
        </div>
      </Dropdown>

      <WorkModalTabs open={open} setOpen={setOpen} progressId={taskProgressId!} />
    </div>
  );
};

export default Actions;
