import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { FileType } from "features/app/utils/enums/fileType";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { partyQueryKeys } from "modules/party/view";

import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { settingsQueryNames } from "../../settings/utils/constants/settingsQueryNames";
import { ProductBodyModel } from "../../settings/utils/models/product/productBodyModel";
import { OrderReqModel, OrderUpdateReqModel } from "../../supply/utils/models/orderReqModel";
import { PartyReqModel } from "../../supply/utils/models/partyReqModel";
import { DefectStatus } from "../utils/constants/DefectStatus";
import { ExcessEnum } from "../utils/constants/ExcessEnum";
import { warehouseEndPoints } from "../utils/constants/warehouseEndPoints";
import { warehouseQueryNames } from "../utils/constants/warehouseQueryNames";
import { ConfirmationUserDefectBodyModel } from "../utils/models/confirmationUserDefectBodyModel";
import { DefectUpdateBodyModel } from "../utils/models/defectUpdateBodyModel";
import { ExcessBodyModel } from "../utils/models/ExcessBodyModel";
import { LaboratoryDeleteModel } from "../utils/models/laboratoryDeleteModel";
import { LaboratorySendBody } from "../utils/models/laboratoryDrawerFormModel";
import { OrderRecievedReqData } from "../utils/models/orderRecievedReqModel";
import { TransferBodyModel } from "../utils/models/transferBodyModel";
import { WarehouseDefectFormModel } from "../utils/models/warehouseDefectFormModel";
import {paymentEndPoints} from "features/payment/utils/constants/paymentEndPoints";

export function useCreateDefect() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, WarehouseDefectFormModel>(
    async data => {
      const res = await $api.post(warehouseEndPoints.CREATE_DEFECT, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_PRODUCTS]);
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_DEFECT]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDefectRejected() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.put(warehouseEndPoints.DEFECT_REJECTED, { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_DEFECT]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useConfirmationUserDefect() {
  return useMutation<SuccessRes, ErrorRes, ConfirmationUserDefectBodyModel>(
    async data => {
      const res = await $api.patch(warehouseEndPoints.CONFIRMATION_USER_DEFECT, data);

      return res.data;
    },
    {
      onError: errorHandler
    }
  );
}

export function useUpdateDefect() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, DefectUpdateBodyModel>(
    async data => {
      const res = await $api.put(warehouseEndPoints.DEFECT_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_DEFECT]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateTransfer() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, TransferBodyModel>(
    async data => {
      const res = await $api.post(warehouseEndPoints.CREATE_TRANSFER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.TRANSFER_VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateTransfer(id?: number) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { searchParams } = useQueryParams();

  return useMutation<SuccessRes, ErrorRes, TransferBodyModel>(
    async data => {
      const res = await $api.put(warehouseEndPoints.UPDATE_TRANSFER, { ...data, id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.ONE_TRANSFER, id]);
        await qc.invalidateQueries([warehouseQueryNames.TRANSFER_VIEW, searchParams]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCheckStatusTransfer(id?: number) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id: number; status: DefectStatus }>(
    async data => {
      const res = await $api.post(warehouseEndPoints.TRANSFER_CHECK, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.ONE_TRANSFER, id]);
        await qc.invalidateQueries([warehouseQueryNames.TRANSFER_VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateExcess() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ExcessBodyModel>(
    async data => {
      const res = await $api.post(warehouseEndPoints.EXCESS_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, data) => {
        await qc.invalidateQueries([warehouseQueryNames.ONE_TRANSFER, data.transfer_id]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCheckExcess() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      status: string;
      transfer_id?: number;
      product_id?: number;
      type: ExcessEnum;
    }
  >(
    async data => {
      const res = await $api.post(warehouseEndPoints.EXCESS_CHECK, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, data) => {
        await qc.invalidateQueries([warehouseQueryNames.ONE_TRANSFER, data.transfer_id]);
        await qc.invalidateQueries([warehouseQueryNames.EXCESS_VIEW, data.transfer_id, data.product_id]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateOrder() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PartyReqModel>(
    async data => {
      const res = await $api.post(warehouseEndPoints.CREATE_ORDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateOrder() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PartyReqModel>(
    async data => {
      const res = await $api.put(warehouseEndPoints.UPDATE_ORDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

// export function useRejectedOrder() {
//   const { i18n } = useTranslation();
//   const qc = useQueryClient();
//
//   return useMutation<SuccessRes, ErrorRes, number>(
//     async id => {
//       const res = await $api.put(warehouseEndPoints.REJECTED_ORDER, { id });
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: async res => {
//         await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
//         await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

export function useDeleteHistoryOrder() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${warehouseEndPoints.DELETE_HISTORY_ORDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
        await qc.invalidateQueries([warehouseQueryNames.HISTORY_ORDER]);
        await qc.invalidateQueries([partyQueryKeys.VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteHistoryOrders() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number[]>(
    async ids => {
      const queryString = ids.map(id => `ids[]=${id}`).join("&");

      const res = await $api.delete(`${warehouseEndPoints.DELETE_HISTORY_ORDER}?${queryString}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
        await qc.invalidateQueries([warehouseQueryNames.HISTORY_ORDER]);
        await qc.invalidateQueries([partyQueryKeys.VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useRecievedOrder() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, OrderRecievedReqData>(
    async data => {
      const res = await $api.put(warehouseEndPoints.RECIEVED_ORDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
        await qc.invalidateQueries([warehouseQueryNames.HISTORY_ORDER]);
        await qc.invalidateQueries([partyQueryKeys.VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateAdditionals() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      id: number;
      less: number;
      more: number;
      defect: number;
    }
  >(
    async data => {
      const res = await $api.post(warehouseEndPoints.CREATE_ADDITIONALS, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteReservation() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, number[]>(
    async ids => {
      const queryIds = queryString.stringify({ ids }, { arrayFormat: "bracket" });

      const res = await $api.delete(`${warehouseEndPoints.DELETE_RESERVATION}?${queryIds}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_RESERVATION, searchParams]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateReservation() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB, queryParamsKeys.SIZE);

  return useMutation<SuccessRes, ErrorRes, OrderReqModel>(
    async data => {
      const res = await $api.post(warehouseEndPoints.WAREHOUSE_RESERVATION_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_RESERVATION, searchParams]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateProduct() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, ProductBodyModel>(
    async data => {
      const res = await $api.post(warehouseQueryNames.PRODUCT_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async (data, variables) => {
        message.success(data.message[i18n.language]);

        if (variables.category_id) {
          await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW]);
        } else {
          await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_VIEW]);
        }
      },
      onError: errorHandler
    }
  );
}

export function useDeleteOrder() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${warehouseEndPoints.DELETE_ORDER}?id=${id}`);

      return res.data;
    },
    {
      retry: false,
      onSuccess: async () => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
      },
      onError: errorHandler
    }
  );
}

export function useWarehouseProductsExportExcel() {
  return useMutation(
    async (data: Record<string, any> & { product_id?: number; unit_id?: number }) => {
      const res = await $api.post(
        `${warehouseEndPoints.WAREHOUSE_PRODUCTS_EXPORT_EXCEL}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: response => {
        const data = response?.data;

        downloadFile(data, "Omborxona mahsulotlari", FileType.XLSX);
      }
    }
  );
}

export function useGetWarehouseOrderExcelFile() {
  const { i18n } = useTranslation();

  return useMutation<any, ErrorRes, any>(
    async body => {
      const res = await $api.post(warehouseEndPoints.ORDER_EXCEL, body, {
        responseType: "blob"
      });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Omborxona-partiyasi", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useUpdateWarehouseProduct() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB, queryParamsKeys.SIZE);

  return useMutation<SuccessRes, ErrorRes, OrderUpdateReqModel>(
    async data => {
      const res = await $api.put(warehouseEndPoints.UPDATE_WAREHOUSE_PRODUCT, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_RESERVATION, searchParam]);
        message.success(res.message[i18n.language]).then();
      }
    }
  );
}

export function useExportWarehouseOrderExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${warehouseEndPoints.WAREHOUSE_ORDERS_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: async response => {
        const data = response?.data;

        downloadFile(data, "Warehouse-orders", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useExportWarehouseDefectExcel() {
  const { i18n } = useTranslation();
  const { searchParams, reqQueryParam } = useQueryParams();

  return useMutation(
    async (data: IExportExcelParam) => {
      let url = "";

      if (searchParams) {
        url += `?${reqQueryParam(queryParamsKeys.PROJECT_IDS_ARR, queryParamsKeys.STATUSES_ARR, queryParamsKeys.WAREHOUSE_IDS_ARR, queryParamsKeys.TYPES_ARR, queryParamsKeys.SEARCH)}`;
      }

      const res = await $api.post(
        `${warehouseEndPoints.WAREHOUSE_DEFECT_EXCEL_EXPORT}`,
        { ...data, ...queryString.parse(url) },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Warehouse-orders", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useCreateLaboratory() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: LaboratorySendBody) => {
      const res = await $api.post(warehouseEndPoints.WAREHOUSE_LABORATORY_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_LABORATORY_VIEW]);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useDeleteLaboratory() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: LaboratoryDeleteModel) => {
      const res = await $api.delete(warehouseEndPoints.WAREHOUSE_LABORATORY_DELETE, { data });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_LABORATORY_VIEW]);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useDeleteTransfer() {
  const { i18n } = useTranslation();
  const { searchParams } = useQueryParams();
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(warehouseEndPoints.WAREHOUSE_TRANSFER_DELETE, { data: { id } });

      return res;
    },
    {
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.TRANSFER_VIEW, searchParams]);
        message.success(res.data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useExportProductReport() {
  const { i18n } = useTranslation();

  return useMutation({
    mutationFn: async (data: Partial<Omit<ProductBodyModel, "date"> & { min_created_date: string; max_created_date: string }>) => {
      const res = await $api.post(warehouseEndPoints.WAREHOUSE_PRODUCT_REPORT_EXCEL, data, { responseType: "blob" });

      return res.data;
    },
    retry: false,
    onSuccess: data => {
      downloadFile(data, "Amallar tarixi hisoboti", FileType.XLSX);
      message.success(data.message[i18n.language]);
    }
  });
}
