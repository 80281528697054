import React from "react";
import { Tabs, TabsProps } from "antd";
import IncomeExpenseHistory from "features/warehouse/components/income-expense-history";
import RightActions from "features/warehouse/components/right-actions";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { queryParamsKeys } from "../app/utils/constants/queryParamsKeys";

import Defect from "./components/defect/Defect";
import Laboratory from "./components/labaratory/Laboratory";
import Orders from "./components/orders/Orders";
import Transfers from "./components/transfers/Transfers";
import TransferModal from "./components/warehouse/transfer-modal/TransferModal";
import Warehouse from "./components/warehouse/Warehouse";
import WarehouseProducts from "./components/warehouse-product/WarehouseProducts";
import { routeSubmodules } from "./utils/constants/routeSubmodules";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { queries, append } = useQueryParams();
  const { submodules } = useRoutePermissions("Omborxona", routeSubmodules);

  const { t } = useTranslation();

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = [
    {
      key: "warehouse",
      label: `${t("warehouse.Omborxona")}`,
      children: <Warehouse />,
      permissionKey: "Omborxona"
    },
    {
      key: "transfer",
      label: `${t("warehouse.O'tkazmalar")}`,
      children: <Transfers />,
      permissionKey: "O'tkazmalar"
    },
    {
      key: "defect",
      label: `${t("warehouse.Yaroqsizlar")}`,
      children: <Defect />,
      permissionKey: "Yaroqsiz mahsulotlar"
    },
    {
      key: "warehouse-products",
      label: `${t("warehouse.Buyurtmalar")}`,
      children: <WarehouseProducts />,
      permissionKey: "Buyurtmalar"
    },
    {
      key: "orders",
      label: `${t("warehouse.Partiyalar")}`,
      children: <Orders />,
      permissionKey: "Partiyalar"
    },
    {
      key: "laboratory",
      label: `${t("warehouse.Laboratoriya")}`,
      children: <Laboratory />,
      permissionKey: "Laboratoriya"
    },
    {
      key: "income-expense-history",
      label: t("warehouse.Amallar tarixi"),
      children: <IncomeExpenseHistory />,
      permissionKey: 'Amallar tarixi'
    }
  ];

  const warehouseItems = (): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    items.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    return newItems;
  };

  return (
    <div className={styles.warehouse}>
      <Tabs
        activeKey={queries()?.[queryParamsKeys.TAB]}
        items={warehouseItems()}
        onChange={onChangeTabs}
        tabBarExtraContent={
          !queries()?.[queryParamsKeys.TAB] || queries()?.[queryParamsKeys.TAB] === "warehouse" ? (
            <RightActions />
          ) : null
        }
      />
      <TransferModal />
    </div>
  );
};

export default Index;
