import React from 'react';
import { Button } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import { useDeleteDoneWorks } from "modules/works/mutations/use-delete-done-works";
import {useRoutePermissions} from "hooks/useRoutePermissions";
import {routeSubmodules} from "modules/works";
import {ConditionalRender} from "components";

const DoneWorks: React.FC = () => {
    const { t } = useTranslation();
    const deleteMutation = useDeleteDoneWorks();
    const dispatch = useAppDispatch();

  const { actions } = useRoutePermissions("Ishlar", routeSubmodules);
  const doneWorksActions = actions("Bajarilgan ishlar");

    const {  setSelectOrderProducts: setSelectProducts } = supplyActions;

    const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

    const onDeleteProducts = (payload?: number[]) => () => {
        const orderIds = payload || selectProducts?.map(item => item.id);

        return deleteMutation.mutateAsync({ ids:orderIds });
    };

    const onAfterDeleteProducts = () => {
        dispatch(setSelectProducts([]));
    };

 return (
  <div>
      <ConditionalRender if={doneWorksActions?.delete}>
        <ModalConfirm
          onOk={onDeleteProducts()}
          title={t("app.Bajarilgan ishlarni o'chirish")}
          onAfterClose={onAfterDeleteProducts}
        >
          <Button datatype="delete">
            <DeleteIcon /> {t("app.O'chirish")}
          </Button>
        </ModalConfirm>
      </ConditionalRender>
  </div>
 );}

export default DoneWorks;
