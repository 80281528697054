import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import $api from "features/app/utils/helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

export const useDeleteDoneWorks = () => {
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  const statsSearchParams = severalSearchParams(queryParamsKeys.SIZE, queryParamsKeys.PAGE, queryParamsKeys.GROUP_BY);
  const qc = useQueryClient();


  return useMutation<any, Error, { ids: number[] }>({
    mutationFn: async data => {
      let url = "work/task-progress-delete";

      if (data?.ids?.length) {
        const params = new URLSearchParams();

        data.ids.forEach(el => params.append("ids[]", el.toString()));
        url += `?${params.toString()}`;
      }
      const res = await $api.delete(url);

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries(["taskProgress", searchParams]);
      qc.invalidateQueries(["works_statistics", statsSearchParams]);
      message.success(data.message[i18n.language]);
    }
  });
};
