import React from "react";
import { Form, Modal } from "antd";
import dayjs from "dayjs";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { useExportProductReport } from "features/warehouse/service/mutation";
import { ProductReport } from "features/warehouse/utils/constants/product-report-model";
import { t } from "i18next";

import { isEmptyArr } from "modules/common";

import Content from "./content";

const { useForm } = Form;

type Props = {
  children: React.ReactNode;
};

const ReportProductModal: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [form] = useForm();
  const mutation = useExportProductReport();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = (values: ProductReport) => {
    const { project_ids, warehouse_ids, date } = values;
    const req = {
      project_ids: isEmptyArr(project_ids) ? undefined : project_ids,
      warehouse_ids: isEmptyArr(project_ids) ? undefined : warehouse_ids,
      min_created_date: date && dayjs(date[0]).format(dayjsFormats.DATE),
      max_created_date: date && dayjs(date[1]).format(dayjsFormats.DATE)
    };

    mutation.mutate(req);
  };

  const onOk = () => form.submit();

  return (
    <>
      {children && <div onClick={handleOpen}>{children}</div>}
      <Modal
        centered
        open={open}
        title={t("warehouse.Hisobotni yuklab olish")}
        className="[&_.ant-modal-content]:rounded-lg"
        okText={t("warehouse.Yuklab olish")}
        cancelText={t("warehouse.Yopish")}
        okButtonProps={{
          loading: mutation.isLoading
        }}
        onOk={onOk}
        onCancel={handleClose}
      >
        <Form form={form} className="mt-5 w-full" onFinish={onFinish} layout="vertical">
          <Content />
        </Form>
      </Modal>
    </>
  );
};

export default ReportProductModal;
