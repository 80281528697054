import React from "react";
import { Button, Dropdown } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useTranslation } from "react-i18next";

import { DotsVerticalIcon } from "components";
import ReportProductModal from "features/warehouse/components/income-expense-history/report-product-modal";

const TitleActions: React.FC = () => {
  const { t } = useTranslation();

  const dropdownRender = (child:React.ReactNode)=>
    <div onClick={e=>e.stopPropagation()}>{child}</div>

  const items = [
    {
      key: "0",
      label: (
        <ReportProductModal>
          <div className="flex items-center gap-3 text-sm font-medium text-gray-700">
            <DownloadIcon />
            {t("payment.Hisobotni yuklab olish")}
          </div>
        </ReportProductModal>
      )
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} dropdownRender={dropdownRender}>
      <Button onClick={e => e.stopPropagation()}>
        <DotsVerticalIcon />
      </Button>
    </Dropdown>
  );
};

export default TitleActions;
