import React from 'react';

import IncomeExpenseHistoryTable from "./table";

const IncomeExpenseHistory: React.FC = () => {
 return (
  <div>
    <IncomeExpenseHistoryTable/>
  </div>
 );}

export default IncomeExpenseHistory;
