import React from "react";
import { DatePicker, Form, Select } from "antd";
import DatepickerSuffixIcon from "features/app/assets/icons/DatepickerSuffixIcon";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "features/app/components/project-select/project-select";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const { Item } = Form;

const Content: React.FC = () => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetWarehouseSelect();

  return (
    <div className="flex w-full flex-col gap-4">
      <ProjectSelect
        multiple
        allowClear
        mode="form"
        label={t("warehouse.Loyiha")}
        name="project_ids"
        initialValue={undefined}
        rootClassName={styles.select}
      />
      <Item name="warehouse_ids" label={t("warehouse.Omborxona")}>
        <Select
          allowClear
          mode="multiple"
          placeholder={t("warehouse.Tanlang")}
          notFoundContent={<TableEmpty />}
          filterOption={selectFilterOption}
          suffixIcon={<SelectSuffixIcon />}
          loading={isFetching}
          options={data?.map(item => ({
            value: item?.id,
            label: item?.name,
            props: {
              name: item?.name
            }
          }))}
        />
      </Item>
      <Item name="date" label="Sana">
        <DatePicker.RangePicker
          format={dayjsFormats.DATE}
          suffixIcon={<DatepickerSuffixIcon />}
          placeholder={[t("warehouse.Boshlang'ich sana"), t("warehouse.Oxirigi sana")]}
          separator={<span className="text-gray-400">-</span>}
        />
      </Item>
    </div>
  );
};

export default Content;
