export const warehouseEndPoints = {
  WAREHOUSE_SELECT: "warehouse/select",
  WAREHOUSE_PRODUCTS: "warehouse-product/view",
  CREATE_DEFECT: "warehouse-defect/create",
  WAREHOUSE_DEFECT: "warehouse-defect/view",
  DEFECT_REJECTED: "warehouse-defect/rejected",
  CONFIRMATION_USER_DEFECT: "confirmation-user/create-or-delete",
  DEFECT_UPDATE: "warehouse-defect/update",
  CREATE_TRANSFER: "warehouse-transfer/create",
  TRANSFER_VIEW: "warehouse-transfer/view",
  UPDATE_TRANSFER: "warehouse-transfer/update",
  WAREHOUSE_PRODUCT_SELECT: "warehouse-product/select",
  TRANSFER_CHECK: "warehouse-transfer/check",
  EXCESS_CREATE: "warehouse-transfer/additional-create",
  EXCESS_VIEW: "warehouse-transfer/additional-view",
  EXCESS_CHECK: "warehouse-transfer/additional-check",
  ORDERS_VIEW: "warehouse-product/order-view",
  CREATE_ORDER: "warehouse-product/order-create",
  UPDATE_ORDER: "warehouse-product/order-update",
  REJECTED_ORDER: "warehouse-product/order-rejected",
  RECIEVED_ORDER: "warehouse-product/order-recieved",
  CREATE_ADDITIONALS: "warehouse-product/additional-create",
  WAREHOUSE_RESERVATION: "warehouse-product/product-view",
  DELETE_RESERVATION: "warehouse-product/delete",
  WAREHOUSE_RESERVATION_PROJECT: "warehouse-product/project-view",
  WAREHOUSE_RESERVATION_PRODUCT: "warehouse-product/task-product-view",
  WAREHOUSE_RESERVATION_CREATE: "warehouse-product/create",
  ADDITIONAL_VIEW: "warehouse-product/additional-view",
  DELETE_ORDER: "warehouse-product/order-delete",
  HISTORY_ORDER: "warehouse-product/product-history-view",
  DELETE_HISTORY_ORDER: "warehouse-product/product-history-delete",
  WAREHOUSE_BY_PRODUCT_ID: "warehouse-product/warehouse-view",
  WAREHOUSE_PRODUCT_BY_WAREHOUSE_ID: "warehouse-product/warehouse-project-view",
  WAREHOUSE_PRODUCTS_EXPORT_EXCEL: "warehouse-product/export-excel",
  ORDER_EXCEL: "warehouse-product/order-export-excel",
  UPDATE_WAREHOUSE_PRODUCT: "warehouse-product/update",
  WAREHOUSE_ORDERS_EXCEL_EXPORT: "warehouse-product/product-export-excel",
  WAREHOUSE_DEFECT_EXCEL_EXPORT: "warehouse-defect/export-excel",
  WAREHOUSE_LABORATORY_CREATE: "laboratory/create",
  WAREHOUSE_LABORATORY_VIEW: "laboratory/view",
  WAREHOUSE_LABORATORY_UPDATE: "laboratory/update",
  WAREHOUSE_LABORATORY_DELETE: "laboratory/delete",
  WAREHOUSE_TRANSFER_DELETE: "warehouse-transfer/delete",
  WAREHOUSE_SINGLE_PRODUCT_INFO:"product/info",
  WAREHOUSE_SINGLE_PRODUCT_HISTORY_STATS:"product/history/statistics",
  WAREHOUSE_SINGLE_PRODUCT_INCOME_EXPENSE_HISTORY_VIEW:"product/history/view",
  WAREHOUSE_CATEGORY_VIEW: "category/warehouse-product-view",
  WAREHOUSE_SUB_CATEGORY_VIEW: "subcategory/warehouse-product-view",
  WAREHOUSE_PRODUCT_HISTORY:'warehouse-product/history',
  WAREHOUSE_PRODUCT_REPORT_EXCEL:'warehouse-product/history-report-export-excel'
};
