import TypeSquareIcon from "../../../settings/assets/icons/TypeSquareIcon";
import CalendarIcon from "../../../supply/assets/icons/CalendarIcon";
import QuantityIcon from "../../../supply/assets/icons/QuantityIcon";
import UnitIcon from "../../../supply/assets/icons/UnitIcon";
import UserIcon from "../../../supply/assets/icons/UserIcon";
import MainWarehouseIcon from "../../assets/icons/MainWarehouseIcon";
import ProjectIcon from "../../assets/icons/ProjectIcon";
import {
  generateGeneralEditingOrderProductData,
  generateGeneralEditingPartyData,
  generateGeneralEditingPaymentData,
  generateGeneralEditingProductData
} from "../helpers/generateGeneralEditingData";

export enum generalEditKeys {
  ORDER_PRODUCTS = "order_products",
  WAREHOUSE_PRODUCTS = "warehouse_products",
  ORDERS = "orders",
  PAYMENT = "payments",
  PAYMENT_ORDER = "payment_order",
  DONE_WORKS = "done_works",
  EMPTY = "empty"
}

export enum generalEditArgKeys {
  UNIT = "unit_id",
  AGENT = "agent_id",
  QUANTITY = "quantity",
  PROJECT = "project_id",
  WAREHOUSE = "warehouse_id",
  PAYMENT_DATE = "payment_date",
  DELIVERY_DATE = "delivery_date",
  DATE = "date",
  DESCRIPTION = "description"
}

export enum generalEditCustomFieldKeys {
  DATE = "date",
  SELECT = "select",
  TEXT = "text",
  FILE = "file"
}

const labelClassName = "flex items-center gap-2";

export const generalEditData = {
  [generalEditKeys.WAREHOUSE_PRODUCTS]: [
    {
      key: "1",
      label: (
        <div className={labelClassName}>
          <MainWarehouseIcon width={1.5} />
          <span>Omborxona</span>
        </div>
      ),
      editKey: generalEditArgKeys.WAREHOUSE
    },
    {
      key: "2",
      label: (
        <div className={labelClassName}>
          <ProjectIcon width={1.5} />
          <span>Loyiha</span>
        </div>
      ),
      editKey: generalEditArgKeys.PROJECT
    },
    {
      key: "3",
      label: (
        <div className={labelClassName}>
          <UnitIcon />
          <span>Birligi</span>
        </div>
      ),
      editKey: generalEditArgKeys.UNIT
    },
    {
      key: "5",
      label: (
        <div className={labelClassName}>
          <QuantityIcon />
          <span>Soni</span>
        </div>
      ),
      editKey: generalEditArgKeys.QUANTITY
    },
    {
      key: "6",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>Kerak bo'lish sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.DELIVERY_DATE
    }
  ],
  [generalEditKeys.ORDERS]: [
    {
      key: "1",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>Kerak bo'lish sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.DELIVERY_DATE
    },
    {
      key: "2",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>To'lov sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.PAYMENT_DATE
    },
    {
      key: "3",
      label: (
        <div className={labelClassName}>
          <UserIcon width={1.5} />
          <span>Vositachi</span>
        </div>
      ),
      editKey: generalEditArgKeys.AGENT
    }
  ],
  [generalEditKeys.ORDER_PRODUCTS]: [
    {
      key: "1",
      label: (
        <div className={labelClassName}>
          <MainWarehouseIcon width={1.5} />
          <span>Omborxona</span>
        </div>
      ),
      editKey: generalEditArgKeys.WAREHOUSE
    },
    {
      key: "2",
      label: (
        <div className={labelClassName}>
          <ProjectIcon width={1.5} />
          <span>Loyiha</span>
        </div>
      ),
      editKey: generalEditArgKeys.PROJECT
    },
    {
      key: "3",
      label: (
        <div className={labelClassName}>
          <UnitIcon />
          <span>Birligi</span>
        </div>
      ),
      editKey: generalEditArgKeys.UNIT
    },
    {
      key: "5",
      label: (
        <div className={labelClassName}>
          <QuantityIcon />
          <span>Soni</span>
        </div>
      ),
      editKey: generalEditArgKeys.QUANTITY
    },
    {
      key: "6",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>Kerak bo'lish sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.DELIVERY_DATE
    }
  ],
  [generalEditKeys.PAYMENT]: [
    {
      key: "1",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>Sana</span>
        </div>
      ),
      editKey: generalEditArgKeys.DATE
    },
    {
      key: "2",
      label: (
        <div className={labelClassName}>
          <TypeSquareIcon />
          <span>Izoh</span>
        </div>
      ),
      editKey: generalEditArgKeys.DESCRIPTION
    },
    {
      key: "3",
      label: (
        <div className={labelClassName}>
          <ProjectIcon width={1.5} />
          <span>Loyiha</span>
        </div>
      ),
      editKey: generalEditArgKeys.PROJECT
    }
  ],
  [generalEditKeys.PAYMENT_ORDER]: [
    {
      key: "1",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>Kerak bo'lish sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.DELIVERY_DATE
    },
    {
      key: "2",
      label: (
        <div className={labelClassName}>
          <CalendarIcon />
          <span>To'lov sanasi</span>
        </div>
      ),
      editKey: generalEditArgKeys.PAYMENT_DATE
    },
    {
      key: "3",
      label: (
        <div className={labelClassName}>
          <UserIcon width={1.5} />
          <span>Vositachi</span>
        </div>
      ),
      editKey: generalEditArgKeys.AGENT
    }
  ],
  [generalEditKeys.EMPTY]: []
};

export const generalEditingReqKeys = {
  [generalEditKeys.ORDER_PRODUCTS]: generalEditKeys.WAREHOUSE_PRODUCTS,
  [generalEditKeys.WAREHOUSE_PRODUCTS]: generalEditKeys.WAREHOUSE_PRODUCTS,
  [generalEditKeys.ORDERS]: generalEditKeys.ORDERS,
  [generalEditKeys.PAYMENT]: generalEditKeys.PAYMENT,
  [generalEditKeys.PAYMENT_ORDER]: generalEditKeys.ORDERS,
  [generalEditKeys.EMPTY]: generalEditKeys.EMPTY
};

export const generalEditFunc = {
  [generalEditKeys.ORDERS]: generateGeneralEditingPartyData,
  [generalEditKeys.WAREHOUSE_PRODUCTS]: generateGeneralEditingProductData,
  [generalEditKeys.ORDER_PRODUCTS]: generateGeneralEditingOrderProductData,
  [generalEditKeys.PAYMENT]: generateGeneralEditingPaymentData,
  [generalEditKeys.PAYMENT_ORDER]: generateGeneralEditingPartyData,
  [generalEditKeys.DONE_WORKS]:generateGeneralEditingOrderProductData,
  [generalEditKeys.EMPTY]: () => []
};

export function generateReqData({
  data,
  copyData,
  requiredKeys
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  copyData: any[];
  requiredKeys: string[];
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any[] = [];

  for (let i = 0; i < data.length; i++) {
    const item = { ...data[i] };
    const copyItem = { ...copyData[i] };
    let generateItem = {};

    for (const k in item) {
      const isRequiredKey = requiredKeys?.some(item => item === k);

      if (item[k] !== copyItem[k] || isRequiredKey) {
        if (k === "custom_field_values") {
          generateItem = {
            ...generateItem,
            // @ts-ignore
            [k]: item[k]?.filter(e => e?.value)
          };
        } else {
          generateItem = {
            ...generateItem,
            [k]: item[k]
          };
        }
      }
    }

    result.push(generateItem);
  }

  return result;
}
